const newGUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

function ObjectID() {
  const timestamp = Math.floor(Date.now() / 1000).toString(16).padStart(8, '0');
  const random = Math.random().toString(16).slice(2, 12).padStart(10, '0');
  const counter = (Math.floor(Math.random() * 0xffffff)).toString(16).padStart(6, '0');
  return timestamp + random + counter;
}

export { newGUID, ObjectID };
