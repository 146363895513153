import { useStyling, Button } from "src/shiftly-ui";

import styles from "../DigitalCV.module.css";

import { faPlus } from "@fortawesome/pro-solid-svg-icons";

const CVEmptyState = ({ text, button, action }) => {
  const styling = useStyling(styles);

  return (
    <div className={styling("empty-container")}>
      <div className={styling("empty-content")}>
        <div className={styling("empty-box")}></div>
        <p>{text}</p>
      </div>
      <div className={styling("empty-btn")}>
        <Button theme={"secondary"} className={styling("empty-btn")} icon={faPlus} iconSide="left">
          {button}
        </Button>
      </div>
    </div>
  );
};

export default CVEmptyState;
