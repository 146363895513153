import { useStyling, Increment } from "src/shiftly-ui";

import styles from "./SFilters.module.css";

const SFWage = ({ wage = 0, setWage }) => {
  const styling = useStyling(styles);

  return (
    <div className={styling("container")}>
      <div className={styling("wage")}>
        <Increment
          label={"Minimum Hourly Rate"}
          unit={"$"}
          side={"left"}
          step={1}
          min={0}
          value={wage}
          setValue={setWage}
        />
      </div>
    </div>
  );
};

export default SFWage;
