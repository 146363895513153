import { useNavigate } from "react-router-dom";
import styles from "./Link.module.css";
import clsx from "clsx";

export const Link = ({ to = "/", children, onClick, state = {}, className }) => {
  const navigate = useNavigate();
  return (
    <span
      className={clsx(styles["container"], className)}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick ? onClick(e) : to.startsWith("http") ? window.open(to, "_blank").focus() : navigate(to, { state });
      }}
    >
      {children}
    </span>
  );
};
