import { useFetch, businessActions } from "src/shiftly-ui";

import { useCallback, useMemo } from "react";

import { useSelector, useDispatch } from "react-redux";

const useShiftlyLocation = () => {
  const business = useSelector((state) => state.business);

  const ui = useSelector((state) => state.ui);

  const activeLocation = useMemo(() => business?.activeLocation, [business?.activeLocation]);

  const dispatch = useDispatch();

  const {
    data: allLocations,
    refetch: refreshAvailableLocations,
    isLoading,
  } = useFetch({
    request: {
      entity: "Location",
      criteria: { business: business.activeBusiness?._id },
      method: "get",
      populate: ["address"],
      id: "availableLocations",
    },
    dependency: business.activeBusiness?._id,
    options: {
      enabled: ui?.settings?.mode === "owner" && !!business.activeBusiness?._id,
    },
    onDataChange: (data) => {
      if (!business.activeLocation) {
        setActiveLocation(data[0]);
      } else {
        const loc = data.find((loc) => loc._id === business.activeLocation._id);

        if (loc) {
          setActiveLocation(loc);
        } else {
          setActiveLocation(data[0]);
        }
      }
    },
  });

  const setActiveLocation = useCallback(
    (location) => {
      let loc = allLocations.find((loc) => loc._id === location._id);

      if (!loc) {
        loc = allLocations[0];
      }

      if (!loc) return;

      dispatch(businessActions.setActiveLocation(loc));

      localStorage.setItem("activeLocation", JSON.stringify(loc));
    },
    [dispatch, allLocations]
  );

  return useMemo(
    () => ({
      activeLocation,
      allLocations,
      setActiveLocation,
      refreshAvailableLocations,
      isLoading,
    }),
    [activeLocation, allLocations, setActiveLocation, refreshAvailableLocations, isLoading]
  );
};

export default useShiftlyLocation;
