import { useState } from "react";
import styles from "./Authentication.module.css";
import { useStyling } from "../../hooks/useStyling";
import { useSearchParams } from "../../hooks/useSearchParams";
import { useFetch } from "src/shiftly-ui";

export const Authentication = (props = {}) => {
  const [page, setPage] = useState(<></>);
  const styling = useStyling(styles);

  const { post } = useFetch();

  useSearchParams(
    ["page"],
    ({ page, confirmToken }) => {
      setPage(page ? props[page] : props.welcome ?? props.signup);
      if (!confirmToken) return;
      post({ node: "auth", method: "confirmAccount", data: { token: confirmToken } });
    },
    [props]
  );

  return <div className={styling("container")}>{page}</div>;
};
