export const calculateAge = (dob = new Date()) => {
  const birthDate = new Date(dob);
  const targetDate = new Date();

  let age = targetDate.getFullYear() - birthDate.getFullYear();
  const monthDifference = targetDate.getMonth() - birthDate.getMonth();
  const dayDifference = targetDate.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
};

export const calculateAgeInYears = (date) => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }

  return age;
};
