import { CentralHeader, useStyling, Each, useFetch, useAuth } from "src/shiftly-ui";

import styles from "./ShiftHistory.module.css";

import ShiftCard from "./components/ShiftCard";

const ShiftHistory = () => {
  const styling = useStyling(styles);

  const { user } = useAuth();

  const { data: shifts } = useFetch({
    request: {
      entity: "Shift",
      method: "getWithCalculatedFields",
      criteria: {
        status: "completed",
        user: user?._id,
      },
      data: {
        user,
      },
    },
    id: "Shift.GetShiftHistory",
    dependency: user?._id,
  });

  return (
    <div className={styling("container")}>
      <CentralHeader text="Shift History" />
      <div className={styling("shifts")}>
        <Each of={shifts} render={({ key, ...shift }) => <ShiftCard key={key} shift={shift} />} />
      </div>
    </div>
  );
};

export default ShiftHistory;
