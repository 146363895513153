import { withInputBase } from "../HOC/withInputBase";
import styles from "./Checkbox.module.css";
import { useStyling } from "../../hooks/useStyling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useInlineStyles } from "../../hooks/useInlineStyles";

export const Checkbox = withInputBase(
  ({ value, setValue, title, label, error, required, disabled, className, formProps, name, ...props }) => {
    const styling = useStyling(styles);
    const [checked, setChecked] = useState(false);
    const style = useInlineStyles(props);

    useEffect(() => {
      setChecked(value);
    }, [value]);

    useEffect(() => {
      if (value === "") setValue(value || false);
    }, [value]);

    const handleClick = useCallback(() => {
      if (disabled) return;
      setChecked((prev) => {
        const newValue = !prev;
        setValue(newValue);
        return newValue;
      });
    }, [disabled, setValue]);

    return (
      <div className={styling("wrapper")}>
        <div
          className={clsx(styling("container", disabled && "disabled"), className)}
          style={style}
          onClick={handleClick}
        >
          <input type="checkbox" {...formProps} checked={checked ?? true} style={{ display: "none" }} />
          <div className={styling("checkbox", checked && "checked")}>
            {checked && <FontAwesomeIcon icon={faCheck} />}
          </div>
          <div className={styling("checkbox-details")}>
            <h6>{title}</h6>
            <p className={styling("label")}>
              {label}
              {required && <span>*</span>}
            </p>
          </div>
        </div>
        {error && (
          <div className={styling("error-container")}>
            <p className={styling("message")}>{error}</p>
          </div>
        )}
      </div>
    );
  }
);
