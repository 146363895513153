import { CentralHeader, useStyling, useFetch, useAuth, Each, useAlerts, Button } from "src/shiftly-ui";

import styles from "./TrashedShifts.module.css";

import ShiftCard from "./components/ShiftCard";

import { useCallback } from "react";

import Illustration from "shiftly-ui/assets/svg/Illustrations/NoShifts.svg";

const TrashedShifts = () => {
  const { user } = useAuth();

  const { confirm } = useAlerts();

  const styling = useStyling(styles);

  const { data: shifts } = useFetch({
    request: {
      entity: "ActionedShift",
      method: "get",
      populate: [
        {
          path: "shift",
          populate: [
            {
              path: "location",
              populate: ["address"],
            },
            {
              path: "position",
            },
          ],
        },
      ],
      criteria: { action: "trashed", user: user?._id },
      id: "ActionedShift.GetTrashedShifts",
    },
    dependency: user,
    options: {
      select: (data = []) => {
        return data.map((item) => ({ ...item.shift }));
      },
    },
  });

  const { post: deleteShiftAction, updateCache } = useFetch({
    options: {
      onMutate: ({ criteria }) => {
        const { shift } = criteria;

        updateCache("ActionedShift.GetTrashedShifts", (data = []) => {
          return data.filter((item) => {
            return item?.shift?._id !== shift;
          });
        });
      },
    },
  });

  const handleRestore = useCallback(
    async (shift) => {
      if (
        !(await confirm({
          label: "Restore Shift - " + shift?.position?.name,
          text: "Are you sure you want to return this shift to your stack?",
          confirmText: "Restore",
          cancelText: "Cancel",
        }))
      )
        return;

      deleteShiftAction({
        entity: "ActionedShift",
        method: "delete",
        criteria: {
          user: user?._id,
          shift: shift._id,
        },
      });
    },
    [confirm, deleteShiftAction, user]
  );

  return (
    <div className={styling("container")}>
      <CentralHeader text={"Trashed Shifts"} chevron link={"/shifts"} />
      <div className={styling("shifts")}>
        {shifts.length ? (
          <Each
            of={shifts}
            render={({ key, ...shift }) => (
              <div className={styling("card-container")} key={key} onClick={() => handleRestore(shift)}>
                <ShiftCard shift={shift} />
              </div>
            )}
          />
        ) : (
          <div className={styles["no-shifts"]}>
            <div className={styles["no-heading"]}>
              <h3>No trashed shifts</h3>
              <p>You have no trashed shifts.</p>
            </div>
            <div className={styles["no-image"]}>
              <img src={Illustration} alt={"No upcoming shifts"} />
            </div>
            <div className={styles["no-button"]}>
              <Button link={"/"} theme={"primary"}>
                Get swiping!
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrashedShifts;
