import { withInputBase } from "../HOC/withInputBase";
import styles from "./Address.module.css";
import { useStyling } from "../../hooks/useStyling.js";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { Input } from "./Input";
import { Dropdown } from "./Dropdown";

export const Address = withInputBase(({ setValue, name }) => {
  const styling = useStyling(styles);
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [post_code, setpost_code] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    setFullAddress(`${street || ""} ${city || ""} ${state || ""} ${post_code || ""}`);
    setVerified(false);
  }, [street, city, state, post_code]);

  useEffect(() => {
    if (!street || !city || !state || !post_code || verified) return;

    const timeoutId = setTimeout(() => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        {
          address: fullAddress,
        },
        function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();

            setValue(
              {
                street,
                city,
                state,
                country: "Australia",
                post_code,
                full_address: fullAddress,
                latitude,
                longitude,
                pinpoint: {
                  type: "Point",
                  coordinates: [longitude, latitude],
                },
              },
              name ?? "address"
            );

            setVerified(true);
          }
        }
      );
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [street, city, state, post_code, fullAddress, setValue, verified, name]);

  return (
    <div className={styling("container")}>
      <div className={styling("street")}>
        <div className={styling("input")}>
          <Input name={"street"} value={street} setValue={setStreet} />
        </div>
      </div>
      <div className={styling("other-inputs")}>
        <div className={styling("input")}>
          <Input name={"city"} value={city} setValue={setCity} />
        </div>
        <div className={styling("input")}>
          <Dropdown name={"state"} value={state} setValue={setState} />
        </div>
        <div className={styling("input")}>
          <Input name={"post_code"} value={post_code} setValue={setpost_code} />
        </div>
      </div>
      {fullAddress.trim() && (
        <div className={styling("validation")}>
          <FontAwesomeIcon icon={verified ? faCheck : faMagnifyingGlass} />
          <p>{fullAddress}</p>
        </div>
      )}
    </div>
  );
});
