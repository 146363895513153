import { useEffect, useState } from "react";

export const useDelayUnmount = (isMounted, duration = 600, minVisibleDuration = 1000) => {
  const [showDiv, setShowDiv] = useState(isMounted);
  const [lastMountedTime, setLastMountedTime] = useState(Date.now());

  useEffect(() => {
    let timeoutId;

    if (isMounted && !showDiv) {
      setShowDiv(true);
      setLastMountedTime(Date.now());
    } else if (!isMounted && showDiv) {
      const timeSinceMounted = Date.now() - lastMountedTime;
      const remainingTime = Math.max(0, minVisibleDuration - timeSinceMounted + duration);

      timeoutId = setTimeout(() => setShowDiv(false), Math.max(duration, remainingTime)); // delay unmount
    }

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, showDiv, minVisibleDuration]);

  return showDiv;
};
