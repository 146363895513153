import styles from "./ModalLabel.module.css";
import { useStyling } from "../../hooks/useStyling";
import logo from "../../assets/logos/icon.svg";

export const ModalLabel = ({ text = "" }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("container")}>
      <img src={logo} alt="Shiftly Logo" className={styles["shiftly-logo"]}></img>
      {typeof text === "string" ? <h3>{text}</h3> : text}
    </div>
  );
};
