import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Pagination } from "swiper/modules";
import { Swiper as SwiperNative, SwiperSlide } from "swiper/react";

export const Swiper = forwardRef(function Swiper(
  {
    slides = [],
    hasPagination = true,
    setSwiper: setExposedSwiper = () => {},
    spaceBetween = 50,
    slidesPerView = 1,
    setPage = () => {},
    ...props
  },
  ref
) {
  const [swiper, setSwiper] = useState(null);

  useImperativeHandle(
    ref,
    () => ({
      slideNext: () => swiper?.slideNext(),
      slidePrev: () => swiper?.slidePrev(),
    }),
    [swiper]
  );

  useEffect(() => {
    if (swiper) {
      setExposedSwiper(swiper);
      setPage(swiper.activeIndex);
    }
  }, [swiper, setExposedSwiper, setPage]);

  return (
    <SwiperNative
      style={{
        "--swiper-pagination-color": "var(--orange)",
      }}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      modules={hasPagination ? [Pagination] : []}
      onSwiper={(swiperInstance) => {
        setSwiper(swiperInstance);
      }}
      onActiveIndexChange={(swiperInstance) => {
        setPage(swiperInstance.activeIndex);
      }}
      pagination={{ clickable: true }}
      {...props}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </SwiperNative>
  );
});

