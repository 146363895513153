import clsx from "clsx";
import styles from "./Overlay.module.css";

export const Overlay = ({ open = false, setOpen = () => {}, className, ...props }) => {
  return (
    <div
    id={'OverlayDIV'}
      className={clsx(styles["overlay"], open && styles["visible"], className)}
      onClick={() => setOpen(false)}
      {...props}
    ></div>
  );
};
