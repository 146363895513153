import clsx from "clsx";
import styles from "./FooterControl.module.css";
import { Button } from "../buttons/Button";

export const FooterControl = ({
  buttonText = "",
  buttonProps = {},
  nextButton,
  nextButtonDisabled = false,
  prevButtonDisabled = false,
  prevButton,
  onButtonClick = () => {},
  onNextClick = () => {},
  onPrevClick = () => {},
  className,
}) => {
  return (
    <div className={clsx(styles["container"], className)}>
      <div
        className={clsx(styles["footer-prev"], prevButtonDisabled && styles["disabled"])}
        onClick={() => {
          if (!prevButtonDisabled) {
            onPrevClick();
          }
        }}
      >
        {prevButton}
      </div>
      <Button onClick={onButtonClick} {...buttonProps}>
        {buttonText}
      </Button>
      <div
        className={clsx(styles["footer-next"], nextButtonDisabled && styles["disabled"])}
        onClick={() => {
          if (!nextButtonDisabled) {
            onNextClick();
          }
        }}
      >
        {nextButton}
      </div>
    </div>
  );
};

