import styles from "./BigToggle.module.css";
import { Each } from "../layout/Each";
import clsx from "clsx";
import { useStyling } from "../../hooks/useStyling";
import { withDisplay } from "../HOC/withDisplay";

export const BigToggle = withDisplay(({ tabs = [], tab = 0, setTab = () => {}, children, isMobile }) => {
  const styling = useStyling(styles); // Use the styling hook for dynamic styling

  return (
    <div className={styling("container")}>
      <div className={styling("tabs")}>
        <Each
          of={tabs}
          render={({ text, count }, index) => (
            <Tab key={index} index={index} active={index === tab} text={text} setTab={setTab} count={count} />
          )}
        />
      </div>
      <div
        className={styling("slider-container")}
        style={{
          width: isMobile ? "100%" : tabs?.length * 180 + "px",
        }}
      >
        <div
          className={styling("slider")}
          style={{
            left: isMobile ? `${(100 / tabs.length) * tab}%` : `${tab * 180}px`,
            width: isMobile ? `${100 / tabs.length}%` : `${180}px`,
          }}
        ></div>
      </div>
      {children}
    </div>
  );
});

const Tab = ({ text, active, setTab, count = 0, index }) => {
  const styling = useStyling(styles); // Use the styling hook here too

  return (
    <div className={clsx(styling("tab"), active && styling("active"))} onClick={() => setTab(index)}>
      {text} {count > 0 && `(${count})`}
    </div>
  );
};
