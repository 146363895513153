import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GlobalLoader } from "../loaders/GlobalLoader";
import { APIProvider } from "@vis.gl/react-google-maps";
import "@copilotkit/react-ui/styles.css";
import "swiper/css";
import "swiper/css/pagination";
import "react-calendar/dist/Calendar.css";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/react-calendar.css";
import "../../styles/MUI.css";
import "../../styles/toastify.css";
import "../../styles/theme.css";
import "../../styles/fonts.css";
import "../../styles/typography.css";
import "../../styles/animation.css";
import "../../styles/typography.css";
import "../../styles/responsive.css";
import "../../styles/spacing.css";
import "../../styles/global.css";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import { SkeletonTheme } from "react-loading-skeleton";
import { withDisplay } from "../HOC/withDisplay";
import { RotateDevice } from "../UI/RotateDevice";
import { authActions } from "../../redux/authSlice";
import { uiActions } from "../../redux/uiSlice";
import { useFetch } from "../../hooks/useFetch";
import { useSocket } from "../../hooks/useSocket";
import { useUI } from "../../hooks/useUI";
import { NavigationProvider } from "../../contexts/Navigation";
import { GestureContextProvider } from "../../contexts/Gestures";
import { AlertsContextProvider } from "../../contexts/Alerts";
import { useDispatch, useSelector } from "react-redux";

export const FunctionalWrapper = withDisplay(({ children, isMobileInLandscape, isTabletInPortrait, init_id }) => {
  const dispatch = useDispatch();
  const UI = useSelector((state) => state.ui);

  useSocket(init_id);

  const { loading, stopLoading } = useUI();

  //Init Auth
  useFetch({
    request: {
      node: "auth-node",
      method: "init",
      id: "initAuth",
    },
    options: {
      onSuccess: ({ user, setting }) => {
        if (user) {
          dispatch(authActions.login(user));
          dispatch(uiActions.setSettings({ ...UI.settings, ...setting }));
        }
        stopLoading("initAuth");
      },
      onError: () => stopLoading("initAuth"),
    },
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <NavigationProvider>
          <AlertsContextProvider>
            <SkeletonTheme baseColor="var(--secondary-200)" highlightColor="var(--secondary-100)">
              <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_JAVASCRIPT} region={"AU"}>
                <GestureContextProvider>
                  <RotateDevice visible={!loading && (isMobileInLandscape || isTabletInPortrait)} />
                  <ToastContainer
                    position="bottom-right"
                    className={"toast-container"}
                    theme="light"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    limit={3}
                  />
                  <GlobalLoader loading={loading} />
                  {children}
                </GestureContextProvider>
              </APIProvider>
            </SkeletonTheme>
          </AlertsContextProvider>
        </NavigationProvider>
      </LocalizationProvider>
    </>
  );
});
