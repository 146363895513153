import styles from "./ShiftCard.module.css";
import { useFetch } from "../../hooks/useFetch";

export const ShiftCard = ({ shift = {}, setShowDetails = () => {} }) => {
  const {
    data: [filledShift = {}],
  } = useFetch({
    request: {
      id: "Shift.GetAdditionalShiftDetailsForShiftCard",
      entity: "Shift",
      method: "getWithCalculatedFields",
      criteria: {
        _id: shift?._id,
      },
      data: {
        user: shift?.user,
      },
    },
  });
  return (
    <>
      <div className={styles["shift-card"]} onClick={() => setShowDetails(filledShift)}>
        <h4>
          {filledShift?.day_of_week} {filledShift?.prettyDate}, {filledShift?.position?.name}
        </h4>
        <p>
          <span>{filledShift?.shiftPeriod}</span>
          <span>{filledShift?.timezone}</span>
        </p>
        <p className={styles["shift-location"]}>{filledShift?.location?.name}</p>
        <p className={styles["shift-address"]}>
          {filledShift?.address?.full_address || filledShift?.location?.address?.full_address}
        </p>
      </div>
    </>
  );
};

export default ShiftCard;
