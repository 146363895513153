import { withInputBase } from "../HOC/withInputBase";
import styles from "./Dropdown.module.css";
import { useStyling } from "../../hooks/useStyling";
import { withInputWrapper } from "../HOC/withInputWrapper";
import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

export const Dropdown = withInputBase(
  withInputWrapper(
    forwardRef(
      (
        {
          value = [],
          setValue,
          disabled,
          formProps,
          onChange,
          defaults,
          placeholder = "Select options",
          options: overrideOptions,
          renderOption,
          disabledFunc = () => false,
          specialButton = null,
          enableSearch = true,
          multiSelect = false,
        },
        ref
      ) => {
        const [selectedValues, setSelectedValues] = useState(multiSelect ? [] : "");
        const [isOpen, setIsOpen] = useState(false);
        const [focusedIndex, setFocusedIndex] = useState(-1);
        const [searchQuery, setSearchQuery] = useState("");
        const [inputFocused, setInputFocused] = useState(false);

        const styling = useStyling(styles);
        const dropdownRef = useRef();
        const containerRef = useRef();
        const options = useMemo(() => overrideOptions ?? defaults.options ?? [], [overrideOptions, defaults]);

        const filteredOptions =
          searchQuery === "" || !inputFocused
            ? options
            : options.filter((option) => option?.label?.toLowerCase().includes(searchQuery?.toLowerCase()));

        useEffect(() => {
          if (multiSelect) {
            const selected = value.map((val) => options.find((opt) => opt.value === val)).filter((val) => val);
            setSelectedValues(selected);
            setSearchQuery(selected?.map((val) => val?.label).join(", ") || "");
          } else {
            const selected = options.find((opt) => opt.value === value);
            setSelectedValues(selected);
            setSearchQuery(selected?.label || "");
          }
        }, [value, options, multiSelect]);

        useEffect(() => {
          function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
              setIsOpen(false);
              setFocusedIndex(-1);
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [containerRef]);

        const handleOptionClick = useCallback(
          (option) => {
            if (disabled) return;
            if (multiSelect) {
              const isSelected = selectedValues.some((val) => val?.value === option?.value);

              const newSelectedValues = isSelected
                ? selectedValues.filter((val) => val?.value !== option?.value)
                : [...selectedValues, option];

              const newval = newSelectedValues.map((val) => val?.value);
              setSelectedValues(newSelectedValues);
              setValue(newval);
              setSearchQuery(newval.join(", "));
              onChange?.(newval);
            } else {
              setSelectedValues(option);
              setValue(option?.value);
              setSearchQuery(option.label);
              setIsOpen(false);
              onChange?.(option?.value);
            }

            const e = {
              target: {
                value: option?.value,
              },
            };

            formProps?.onChange?.(e);
          },
          [setValue, selectedValues, multiSelect, disabled, onChange, formProps]
        );

        useImperativeHandle(ref, () => ({
          onClick: () => {
            !disabled && setIsOpen(true);
          },
        }));

        const handleKeyDown = useCallback(
          (e) => {
            if (disabled) return;
            if (e.key === "ArrowDown") {
              setIsOpen(true);
              setFocusedIndex((prevIndex) => (prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex));
            } else if (e.key === "ArrowUp") {
              setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
            } else if (e.key === "Enter" && focusedIndex >= 0) {
              e.preventDefault();
              handleOptionClick(filteredOptions[focusedIndex]);
            } else if (e.key === "Escape") {
              setIsOpen(false);
              setFocusedIndex(-1);
            }
          },
          [focusedIndex, filteredOptions, handleOptionClick, disabled]
        );

        useEffect(() => {
          if (isOpen && focusedIndex >= 0) {
            const focusedElement = dropdownRef.current?.children[focusedIndex];
            focusedElement?.scrollIntoView({ block: "nearest" });
          }
        }, [focusedIndex, isOpen]);

        return (
          <div className={styling("wrapper")} ref={containerRef}>
            <input {...formProps} style={{ visibility: "hidden", position: "absolute", opacity: 0 }} />
            {specialButton && (
              <div className={styling("special-btn")} onClick={specialButton.action}>
                <FontAwesomeIcon icon={specialButton.icon} />
              </div>
            )}
            <div
              className={styling("container")}
              onClick={() => {
                if (disabled) return;
                setIsOpen(true);
                setSearchQuery("");
              }}
              onKeyDown={handleKeyDown}
              role="button"
              aria-expanded={isOpen}
            >
              <FontAwesomeIcon
                icon={faChevronDown}
                className={clsx(styling("chevron"), isOpen && styling("chev-open"))}
              />

              <div className={clsx(styling("input"), styling("dropdown"))} ref={dropdownRef}>
                {enableSearch ? (
                  <input
                    className={clsx(styling("search-input"))}
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    disabled={disabled}
                    onChange={(e) => {
                      setSearchQuery(e.target?.value);
                      setIsOpen(true);
                    }}
                    onBlur={(e) => {
                      setTimeout(() => {
                        const option = options.find(
                          (opt) =>
                            opt.label?.toString()?.toLowerCase() === e.target?.value?.toString().toLocaleLowerCase()
                        );
                        if (option) {
                          handleOptionClick(option);
                        } else if (selectedValues && !isOpen) {
                          setSearchQuery(
                            multiSelect ? selectedValues?.map((val) => val?.label).join(", ") : selectedValues?.label
                          );
                        }
                        setInputFocused(false);
                      }, 200);
                    }}
                    onFocus={() => setInputFocused(true)}
                    ref={ref}
                  />
                ) : (
                  <div className={styling("selected-values")}>
                    {multiSelect ? (
                      selectedValues.length > 0 ? (
                        selectedValues.map((val) => (
                          <span key={val?.value} className={styling("selected-value")}>
                            {val.label}
                          </span>
                        ))
                      ) : (
                        <span className={styling("placeholder")}>{placeholder}</span>
                      )
                    ) : (
                      selectedValues?.label ?? <span className={styling("placeholder")}>{placeholder}</span>
                    )}
                  </div>
                )}
              </div>

              <ul className={clsx(styling("dropdown-menu"), isOpen && styling("isOpen"))} ref={dropdownRef}>
                {filteredOptions.map((option, index) => (
                  <li
                    key={option?.value}
                    className={clsx(
                      styling("option"),
                      multiSelect
                        ? selectedValues.some((val) => val?.value === option?.value) && styling("selected-option")
                        : selectedValues?.value === option.value && styling("selected-option"),
                      disabledFunc(option) && styling("disabled"),
                      focusedIndex === index && styling("focused-option")
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      !disabledFunc(option) && handleOptionClick(option);
                    }}
                    role="option"
                    aria-selected={
                      multiSelect
                        ? selectedValues.some((val) => val?.value === option?.value)
                        : selectedValues?.value === option.value
                    }
                  >
                    {renderOption ? renderOption(option) : option.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      }
    )
  )
);
