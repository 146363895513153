import styles from "./GlobalLoader.module.css";
import ShiftlyAustralia from "../../assets/svg/Shiftly_Australia.svg";
import { BlobSvg } from "../decorative/BlobSvg";
import clsx from "clsx";
import { LoadingSpinner } from "./LoadingSpinner";

export const GlobalLoader = ({ loading }) => {
  return (
    <div className={clsx(styles["container"], !loading && "fadeOut")}>
      <div className={clsx(styles["blob"], styles["blob-1"])}>
        <BlobSvg />
      </div>
      <div className={clsx(styles["blob"], styles["blob-2"])}>
        <BlobSvg />
      </div>
      <div className={clsx(styles["blob"], styles["blob-3"])}>
        <BlobSvg />
      </div>
      <div className={styles["content"]}>
        {appIcon}
        <img src={ShiftlyAustralia} alt="Shiftly Australia" className={styles["shiftly-australia"]} />
        <LoadingSpinner className={styles["spinner"]} />
      </div>
    </div>
  );
};


const appIcon = (
  <svg
    width="140"
    height="140"
    viewBox="0 0 1307 1308"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles["icon"]}
  >
    <rect y="0.5" width="1307" height="1307" rx="100" fill="white" />
    <g filter="url(#filter0_i_1369_21160)">
      <path
        d="M652.888 712.455C585.742 712.455 531.31 658.023 531.31 590.878C531.31 523.733 585.742 469.301 652.888 469.301C720.033 469.301 774.465 523.733 774.465 590.878C774.465 658.023 720.033 712.455 652.888 712.455Z"
        fill="#F6C7B1"
      />
    </g>
    <g filter="url(#filter1_di_1369_21160)">
      <path
        d="M616.684 196.247C413.753 214.855 255 384.305 255 590.248C255 808.61 542.032 1129 653.851 1129C713.162 1129 828.791 1031.02 920.478 906.319L766.487 753.547C734.656 775.41 695.75 788.096 653.851 788.096C543.664 788.096 454.154 699.294 454.154 589.978C454.154 493.261 524.221 412.601 616.684 395.294V196.247Z"
        fill="#ED4902"
      />
      <path
        d="M691.255 395.338C717.133 400.214 741.252 410.053 762.566 423.818L904.403 282.112C845.009 234.479 771.57 203.417 691.255 196V395.338Z"
        fill="#ED4902"
      />
      <path
        d="M850.101 553.172C844.64 524.401 832.897 497.815 816.344 474.87L957.692 333.653C1009.42 393.93 1043.25 469.794 1051 553.172H850.101Z"
        fill="#ED4902"
      />
      <path
        d="M849.95 627.675C844.795 654.289 834.279 678.989 819.541 700.644L963.193 843.159C1007.42 772.002 1040.64 696.25 1050.03 627.675H849.95Z"
        fill="#ED4902"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1369_21160"
        x="528.311"
        y="469.301"
        width="246.154"
        height="243.155"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-3" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.886473 0 0 0 0 0.833333 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1369_21160" />
      </filter>
      <filter
        id="filter1_di_1369_21160"
        x="235"
        y="180"
        width="836"
        height="973"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.608333 0 0 0 0 0.608333 0 0 0 0 0.608333 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1369_21160" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1369_21160" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-3" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.359079 0 0 0 0 0.0833333 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1369_21160" />
      </filter>
    </defs>
  </svg>
);
