import { useCallback, useMemo } from "react";

export const useEntity = (toFind) => {
  const entities = useMemo(
    () => ({
      Business: {
        Business: "Business.Business",
        Location: "Business.Location",
        Payment: "Business.Payment",
        PaymentMethod: "Business.PaymentMethod",
        ScheduleTemplate: "Business.ScheduleTemplate",
      },
      Employment: {
        ActionedShift: "Employment.ActionedShift",
        Award: "Employment.Award",
        Employment: "Employment.Employment",
        EmployeeOnboard: "Employment.EmployeeOnboard",
        InternalShift: "Employment.InternalShift",
        PayRun: "Employment.PayRun",
        PaySlip: "Employment.PaySlip",
        Position: "Employment.Position",
        Shift: "Employment.Shift",
        ShiftApplication: "Employment.ShiftApplication",
        ShiftHistory: "Employment.ShiftHistory",
        Timesheet: "Employment.Timesheet",
      },
      Shared: {
        Address: "Shared.Address",
        Rating: "Shared.Rating",
      },
      User: {
        Account: "User.Account",
        BankAccount: "User.BankAccount",
        InternalStaff: "User.InternalStaff",
        Profile: "User.Profile",
        Setting: "User.Setting",
        SuperMembership: "User.SuperMembership",
        TaxDeclaration: "User.TaxDeclaration",
        User: "User.User",
      },
      Utility: {
        Authentication: "Utility.Authentication",
        Conversation: "Utility.Conversation",
        Event: "Utility.Event",
        Filter: "Utility.Filter",
        Industry: "Utility.Industry",
        Integration: "Utility.Integration",
        Notifications: "Utility.Notifications",
        Message: "Utility.Message",
        PositionGroup: "Utility.PositionGroup",
        PushSubscription: "Utility.PushSubscription",
        SharedAccess: "Utility.SharedAccess",
        SuperFund: "Utility.SuperFund",
      },
    }),
    []
  );

  const findEntity = useCallback((toFind) => {
    const foundEntities = [];

    // Iterate through each category of entities
    for (const category in entities) {
      if (Object.hasOwnProperty.call(entities, category)) {
        const categoryEntities = entities[category];

        // Check if any of the entity names exist directly in this category
        for (const entityName of toFind) {
          if (Object.hasOwnProperty.call(categoryEntities, entityName)) {
            foundEntities.push(categoryEntities[entityName]);
          } else {
            // Check if the entityName exists in nested entities of this category
            for (const nestedCategory in categoryEntities) {
              if (Object.hasOwnProperty.call(categoryEntities, nestedCategory)) {
                const nestedEntity = categoryEntities[nestedCategory];
                if (nestedEntity === entityName) {
                  foundEntities.push(nestedEntity);
                  break;
                }
              }
            }
          }
        }
      }
    }

    return foundEntities.length === 1 ? foundEntities[0] : foundEntities;
  }, []);

  if (!toFind) return { ...entities, findEntity };

  return findEntity(Array.isArray(toFind) ? toFind : [toFind]);
};
