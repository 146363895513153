import { useStyling, formatPayPeriod, Each, Link, useFetch, CentralHeader } from "src/shiftly-ui";

import styles from "./PayRunBreakdown.module.css";

import { motion } from "framer-motion";

import Illustration from "shiftly-ui/assets/svg/Illustrations/NoShifts.svg";

const superPercent = (Number(process.env.REACT_APP_SHIFTLY_SUPER_RATE) * 100).toString() + "%";

const PayRunBreakdown = ({ show = false, setShow = () => {}, payrun = {}, timesheets = [] }) => {
  const styling = useStyling(styles);

  const { start_date, end_date } = payrun;

  const { data: applications } = useFetch({
    request: {
      entity: "ShiftApplication",
      criteria: {
        shift: { $in: timesheets.map(({ shift }) => shift._id) },
        status: "accepted",
      },
    },
    dependency: timesheets,
  });

  const applicationMap = applications.reduce((acc, application) => {
    acc[application.shift] = application;

    return acc;
  }, {});

  const periodTotal = applications.reduce((acc, application) => {
    acc += application.subtotal;

    return acc;
  }, 0);

  return (
    <motion.div
      initial={{
        x: "100%",
      }}
      animate={{ x: show ? "0" : "100%" }}
      transition={{ duration: 0.2 }}
      className={styling("container")}
    >
      <CentralHeader chevron text={`Payrun: ${formatPayPeriod(start_date, end_date)}`} setShow={setShow} />
      <div className={styling("subtext")}>
        <p>
          Payments may take 1 - 2 business days to show in your account once they are processed. You will receive a
          payslip once processing has completed.
        </p>
      </div>
      <div className={styling("shifts")}>
        {timesheets.length ? (
          <Each
            of={timesheets}
            render={({ key, ...timesheet }) => (
              <ShiftBox key={key} shift={timesheet?.shift} application={applicationMap[timesheet?.shift?._id]} />
            )}
          />
        ) : (
          <div className={styling("no-shifts")}>
            <img src={Illustration} className={styling("illustration")} alt={"no-shifts"} />
            <p>You haven't worked any shifts this pay period.</p>
          </div>
        )}
      </div>
      <div className={styling("total-due")}>
        <div className={styling("total")}>
          <h4>
            Total Due <span>(Excl. Tax): </span>
          </h4>
          <h4>${periodTotal}</h4>
        </div>
        <div className={styling("total-super")}>
          <p>{superPercent} superannuation will be paid to your linked provider seperately</p>
        </div>
      </div>
      <div className={styling("contact")}>
        <p>
          If you have any queries or discrepebcies, please <Link to="/contact">contact Shiftly</Link> immediately.
        </p>
      </div>
    </motion.div>
  );
};

const ShiftBox = ({ shift, application }) => {
  const styling = useStyling(styles);

  const { prettyDate, shiftPeriod, location, position } = shift;

  return (
    <div className={styling("shift-container")}>
      <div className={styling("pretty-date")}>
        <h4>
          {prettyDate} - <span>{shiftPeriod}</span>
        </h4>
      </div>
      <div className={styles["loc-pos"]}>
        <p>
          {location?.name} - {position?.name}
        </p>
      </div>
      <div className={styles["amount"]}>
        <h4>
          Amount due: <span>(Excl. Tax):</span>
        </h4>
        <h4>${application?.subtotal}</h4>
      </div>
      <div className={styling("super")}>+ {superPercent} super</div>
    </div>
  );
};

export default PayRunBreakdown;
