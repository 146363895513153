import styles from "./SideLayout.module.css";
import { useStyling } from "../../hooks/useStyling";
import { motion } from "framer-motion";
import { CentralHeader } from "./CentralHeader";
import { useUncontrolled } from "uncontrollable";
import clsx from "clsx";
import { useGestures } from "../../contexts/Gestures.js";

export const SideLayout = (props) => {
  const {
    direction = "right",
    heading = "Heading",
    show,
    setShow,
    className,
    children,
  } = useUncontrolled(props, { show: "setShow" });

  const styling = useStyling(styles);

  useGestures({
    onSwipe: (direction) => {
      if (direction === "right" && show) {
        setShow(false);
      }
    },
  });

  const variants = {
    right: {
      hidden: { x: "100%" },
      visible: { x: "0%" },
    },
    left: {
      hidden: { x: "-100%" },
      visible: { x: "0%" },
    },
    up: {
      hidden: { y: "-100%" },
      visible: { y: "0%" },
    },
    down: {
      hidden: { y: "100%" },
      visible: { y: "0%" },
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate={show ? "visible" : "hidden"}
      variants={variants[direction]}
      transition={{ duration: 0.2 }}
      className={clsx(styling("container"), className)}
    >
      <CentralHeader show={show} setShow={setShow} text={heading} chevron />
      <div className={styling("content")}>{children}</div>
    </motion.div>
  );
};
