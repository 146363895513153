import { AlertModal, useStyling, Each } from "src/shiftly-ui";

import styles from "./OnboardingIncomplete.module.css";

import useOnboardingComplete from "src/hooks/useOnboardingComplete";

import { useEffect, useState } from "react";

import {
  faBank,
  faCertificate,
  faDollar,
  faInstitution,
  faPencil,
  faPhone,
  faTools,
} from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useNavigate } from "react-router-dom";

const OnboardingIncomplete = ({ show, setShow, shift }) => {
  const styling = useStyling(styles);

  const [itemsToComplete, setItemsToComplete] = useState([]);

  const { todo } = useOnboardingComplete();

  const navigate = useNavigate();

  useEffect(() => {
    const items = [];

    if (!todo.bio) items.push({ text: "Bio", icon: faPencil });

    if (!todo.qualifications) items.push({ text: "Qualifications", icon: faCertificate });

    if (!todo.workExperience) items.push({ text: "Work Experience", icon: faTools });

    if (!todo.personalDetails) items.push({ text: "Personal Details", icon: faPencil });

    if (!todo.contactDetails) items.push({ text: "Contact Details", icon: faPhone });

    if (!todo.bankDetails) items.push({ text: "Bank Details", icon: faBank });

    if (!todo.taxDetails) items.push({ text: "Tax Details", icon: faDollar });

    if (!todo.superDetails) items.push({ text: "Super Details", icon: faInstitution });

    setItemsToComplete(items.slice(0, 3));
  }, [todo]);

  return (
    <AlertModal
      label="Complete your profile"
      showModal={show}
      setShowModal={setShow}
      buttonText={"Complete Profile"}
      skipButtonText={"Maybe Later"}
      onClick={() => {
        navigate("/profile", {
          state: {
            shift: shift,
          },
        });
      }}
    >
      <div className={styling("image-container")}></div>
      <div className={styling("heading")}>
        <h2>Uh oh! Please complete your onboarding</h2>
      </div>
      <div className={styling("text")}>
        <p>
          To help you secure shifts, please ensure your profile is complete. This only takes a few minutes and will make
          you eligible to apply.
        </p>
      </div>
      <div className={styling("todo")}>
        <p className={styling("some-items")}>Some items to complete include:</p>
        <Each
          of={itemsToComplete}
          render={(item, index) => (
            <div className={styling("item")} key={index}>
              <div className={styling("icon")}>
                <FontAwesomeIcon icon={item.icon} />
              </div>
              <div className={styling("text")}>
                <p>{item.text}</p>
              </div>
            </div>
          )}
        />
      </div>
    </AlertModal>
  );
};

export default OnboardingIncomplete;
