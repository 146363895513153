import { forwardRef } from "react";
import { useFormProps } from "../../hooks/useFormProps.js";
import { formDefaults } from "../../utility/form.js";
import { useUncontrolled } from "uncontrollable";

export const withInputBase = (Component) => {
  const WithRefWrapper = forwardRef((props, ref) => {
    const controlledProps = useUncontrolled(props, { value: "setValue" });
    const { submit, errors, ...formProps } = useFormProps(controlledProps);
    const { name, required, label, placeholder, link, type, submitOnEnter, onEnterPress = () => {} } = controlledProps;

    return (
      <Component
        ref={ref}
        {...controlledProps}
        {...formProps}
        required={required ?? formDefaults[name]?.required}
        label={label ?? formDefaults[name]?.label}
        placeholder={placeholder ?? formDefaults[name]?.placeholder}
        type={type ?? formDefaults[name]?.type}
        link={link ?? formDefaults[name]?.link}
        defaults={formDefaults[name] || {}}
        error={errors?.[name]}
        errors={errors}
        validate={props.validate ?? (() => {})}
        onKeyDown={(e) => {
          props.onKeyDown?.(e);
          e.key === "Enter" && onEnterPress();
          if (submitOnEnter && e.key === "Enter") {
            submit();
          }
        }}
      />
    );
  });

  return WithRefWrapper;
};
