import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    viewMode: "scroll",
  },
  reducers: {
    setViewMode(state, action) {
      state.viewMode = action.payload;
    },
  },
});

export const filterActions = filterSlice.actions;

export const filtersSliceReducer = filterSlice.reducer;
