import styles from "./RotateDevice.module.css";
import clsx from "clsx";
import { BlobSvg } from "../decorative/BlobSvg";
import { useEffect, useState } from "react";
import { useDocument } from "../../hooks/useDocument";
import { useUI } from "../../hooks/useUI.js";

export const RotateDevice = ({ visible }) => {
  const { setThemeColour } = useDocument();
  const { loading } = useUI();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setShow(true);
      }, 300);
    } else {
      setShow(false);
    }
  }, [visible]);

  useEffect(() => {
    show || loading ? setThemeColour("#ed4902") : setThemeColour("white");
  }, [show, setThemeColour, loading]);

  return (
    <div className={clsx(styles["container"], show && styles["visible"])}>
      <div className={styles["content"]}>
        <h1>Please rotate your device</h1>
      </div>
      <div className={clsx(styles["blob"], styles["blob-1"])}>
        <BlobSvg />
      </div>
      <div className={clsx(styles["blob"], styles["blob-2"])}>
        <BlobSvg />
      </div>
      <div className={clsx(styles["blob"], styles["blob-3"])}>
        <BlobSvg />
      </div>
    </div>
  );
};

