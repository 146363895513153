import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./Alerts.module.css";
import { useStyling } from "../hooks/useStyling";
import { useDisplay } from "../hooks/useDisplay";
import { motion } from "framer-motion";
import { Modal } from "../components/modals/Modal";
import { Overlay } from "../components/modals/Overlay";
import { ModalLabel } from "../components/modals/ModalLabel";
import { Button } from "../components/buttons/Button";
import { Input } from "../components/inputs/Input";
import { Dropdown } from "../components/inputs/Dropdown";
import clsx from "clsx";

export const AlertsContext = createContext();

export const AlertsContextProvider = ({ children }) => {
  const awaitingPromiseRef = useRef(null);
  const [options, setOptions] = useState(null);
  const { isMobile } = useDisplay();
  const [promptValue, setPromptValue] = useState("");

  const promptValueRef = useRef(promptValue);

  const styling = useStyling(styles);

  useEffect(() => {
    promptValueRef.current = promptValue;
  }, [promptValue]);

  const openModal = useCallback((options) => {
    setOptions(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const handleClose = useCallback(() => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);
    }
    setOptions(null);
  }, []);

  const handleConfirm = useCallback((value) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(value);
    }
    setOptions(null);
  }, []);

  const prompt = useCallback(
    async ({
      label = "Enter a value",
      text = "Please enter a value",
      cancelText = "Cancel",
      confirmText = "Submit",
      modalClassName,
      className,
      type = "text",
      inputProps = {},
    } = {}) => {
      
      const input =
        type === "dropdown" ? (
          <Dropdown value={promptValue} setValue={setPromptValue} {...inputProps} />
        ) : (
          <Input value={promptValue} setValue={setPromptValue} {...inputProps} />
        );

      const value = await openModal({
        label,
        className: modalClassName,
        content: (
          <>
            <div className={clsx(styling("content-container"), className)}>
              <div className={styling("content-text")}>{text}</div>
              <div className={styling("content-input")}>{input}</div>
              <div className={styling("buttons-container")}>
                <Button
                  onClick={() => {
                    handleClose();
                    setPromptValue("");
                  }}
                  theme={"secondary-outline"}
                >
                  {cancelText}
                </Button>
                <Button
                  onClick={() => {
                    handleConfirm(promptValueRef.current);
                    setPromptValue("");
                  }}
                  theme={"secondary"}
                >
                  {confirmText}
                </Button>
              </div>
            </div>
          </>
        ),
      });

      return value;
    },
    [openModal, handleClose, handleConfirm, styling, promptValue]
  );

  const confirm = useCallback(
    async ({
      label = "Are you sure?",
      text = "Are you sure you want to complete this action?",
      cancelText = "Cancel",
      confirmText = "Submit",
      inverse = false,
    } = {}) =>
      await openModal({
        label,
        content: (
          <>
            <div className={styling("content-container")}>
              <div className={styling("content-text")}>{text}</div>
              <div className={styling("buttons-container")}>
                <Button onClick={handleClose} theme={"secondary-outline"}>
                  {cancelText}
                </Button>
                <Button onClick={handleConfirm} theme={inverse ? "danger" : "secondary"}>
                  {confirmText}
                </Button>
              </div>
            </div>
          </>
        ),
      }),
    [openModal, handleClose, styling, handleConfirm]
  );

  return (
    <>
      <AlertsContext.Provider value={{ confirm, prompt, alert }} children={children} />
      {!isMobile ? (
        <Modal
          showModal={Boolean(options)}
          label={<ModalLabel text={options?.label} />}
          setShowModal={handleClose}
          className={clsx(styling("modal"), options?.className)}
        >
          {options?.content}
        </Modal>
      ) : (
        <div className={styling("mobile-wrapper", Boolean(options) && "mobile-visible")}>
          <Overlay open={Boolean(options)} onClick={handleClose} />
          <motion.div
            initial={{
              y: "100%",
            }}
            animate={{
              y: Boolean(options) ? "0%" : "100%",
            }}
            transition={{ duration: 0.2 }}
            className={styling("modal-mobile")}
          >
            <div className={styling("label-mobile")}>
              <h3>{options?.label}</h3>
            </div>
            {options?.content}
          </motion.div>
        </div>
      )}
    </>
  );
};

export const useAlerts = () => useContext(AlertsContext);
