import { useGestures } from "src/shiftly-ui";

import styles from "./ShifterWrapper.module.css";

import { useLocation } from "react-router-dom";

const ShifterWrapper = ({ children }) => {
  const { bind } = useGestures();

  const { pathname } = useLocation();

  return (
    pathname !== "/portal" && (
      <div {...bind()} className={styles["container"]}>
        {children}
      </div>
    )
  );
};

export default ShifterWrapper;
