import { useDisplay } from "../../hooks/useDisplay";

export const withDisplay = (Component) => {
  return (props) => {
    const display = useDisplay();

    if (!Object.values(display).every((value) => value !== null)) return null;

    return <Component {...props} {...display} />;
  };
};
