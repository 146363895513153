import { createSlice } from "@reduxjs/toolkit";

const businessSlice = createSlice({
  name: "business",
  initialState: {
    activeBusiness: null,
    activeLocation: null,
  },
  reducers: {
    setActiveBusiness(state, action) {
      state.activeBusiness = action.payload;
    },
    setActiveLocation(state, action) {
      localStorage.setItem("activeLocation", JSON.stringify(action.payload));
      state.activeLocation = action.payload;
    },
  },
});

export const businessActions = businessSlice.actions;

export const businessSliceReducer = businessSlice.reducer;
