import { withInputBase } from "../HOC/withInputBase";
import styles from "./RadioButtons.module.css";
import { useStyling } from "../../hooks/useStyling";
import { useState, useImperativeHandle, forwardRef, useCallback, useEffect } from "react";
import { useInlineStyles } from "../../hooks/useInlineStyles";
import { Link } from "../buttons/Link";
import clsx from "clsx";

export const RadioButtons = withInputBase(
  forwardRef(
    (
      {
        value,
        setValue,
        multiselect = false,
        required,
        error,
        options = [],
        align = "flex-start",
        type = "list",
        label,
        link = {},
        className,
        onChange,
        formProps,
        ...props
      },
      ref
    ) => {
      const [selectedOption, setSelectedOption] = useState(multiselect ? [] : {});

      const styling = useStyling(styles);
      const style = useInlineStyles(props);

      useImperativeHandle(ref, () => ({
        refresh: () => {
          setSelectedOption(multiselect ? [] : {});
        },
      }));

      const handleOptionClick = useCallback(
        (option, index) => {
          if (multiselect) {
            // For multiselect, toggle the option in the selected array
            setSelectedOption((prev) => {
              const exists = prev.find((o) => o.value === (option.value || option));
              const updated = exists
                ? prev.filter((o) => o.value !== (option.value || option))
                : [...prev, { value: option.value || option, index }];
              setValue(updated.map((o) => o.value));
              onChange?.(updated.map((o) => o.value));
              return updated;
            });
          } else {
            // For single selection, set the selected option
            setSelectedOption({ value: option.value || option, index });
            setValue(option.value || option);
            onChange?.(option.value || option);
          }
        },
        [setValue, multiselect]
      );

      useEffect(() => {
        if (multiselect) {
          const selected = options
            .filter((option) => value.includes(option.value || option))
            .map((option) => ({
              value: option.value || option,
              index: options.indexOf(option),
            }));

          setSelectedOption(selected);
        } else {
          const selected = options.find((option) => option.value === value);
          selected && setSelectedOption({ value: selected.value, index: options.indexOf(selected) });
        }
      }, [value, options, multiselect]);

      return (
        <div className={styling("wrapper")} style={{ ...style }}>
          <div className={styling("header")}>
            {label && (
              <label className={styling("label")}>
                {label}: {required && <span>*</span>}
              </label>
            )}
            {link?.destination && (
              <Link to={link.destination} className={styling("link")}>
                {link.label}
              </Link>
            )}
          </div>
          <div
            className={clsx(styling("container"), type === "cards" && styling("cards-container"), className)}
            style={{ justifyContent: align }}
          >
            {options.map((option, index) => (
              <div
                key={index}
                className={clsx(
                  type === "list" ? styling("radio-button-container") : styling("radio-button-card"),
                  (multiselect ? selectedOption?.some((o) => o.index === index) : selectedOption.index === index) &&
                    styling("active")
                )}
                onClick={() => handleOptionClick(option, index)}
              >
                {type === "list" ? (
                  <>
                    <p>{option.label || option}</p>
                    <div className={styling("radio-button")}>
                      <div className={styling("radio-button-inner")}></div>
                    </div>
                  </>
                ) : (
                  option.content
                )}
              </div>
            ))}
          </div>
          <input {...formProps} style={{ visibility: "hidden", position: 'absolute', opacity: 0 }} />
          {error && (
            <div className={styling("error-container")}>
              <p className={styling("message")}>{error}</p>
            </div>
          )}
        </div>
      );
    }
  )
);
