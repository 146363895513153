import {
  authSliceReducer,
  uiSliceReducer,
  filtersSliceReducer,
  shiftSliceReducer,
  businessSliceReducer,
  awardSliceReducer,
} from "src/shiftly-ui";

import { configureStore } from "@reduxjs/toolkit";

import { thunk } from "redux-thunk";

const asyncFunctionMiddleware = (storeAPI) => (next) => (action) => {
  // If the "action" is actually a function instead...
  if (typeof action === "function") {
    // then call the function and pass `dispatch` and `getState` as arguments
    return action(storeAPI.dispatch, storeAPI.getState);
  }

  // Otherwise, it's a normal action - send it onwards
  return next(action);
};

const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    ui: uiSliceReducer,
    filters: filtersSliceReducer,
    shifts: shiftSliceReducer,
    business: businessSliceReducer,
    awards: awardSliceReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(asyncFunctionMiddleware, thunk),
});

export default store;
