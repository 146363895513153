import { useCallback, useEffect, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { customFetch } from "../utility/http";
import { useEntity } from "./useEntity";
import { ObjectID } from "../utility/generators";

const fetchData = async (request) => {
  try {
    const payloads = request.payloads?.map((payload) => {
      if (!payload.entity && !request.node) {
        throw new Error("Entity is required");
      }
      return { ...payload, method: payload.method || "get" };
    });

    const { data } = await customFetch({ payloads, node: request.node ?? "live-node", headers: request.headers ?? {} });
    return data;
  } catch (error) {
    throw error;
  }
};

const addIDToData = (data) => {
  return Array.isArray(data)
    ? data.map((item) => ({ ...item, _id: item._id || ObjectID() }))
    : Object.keys(data).length > 0
    ? { ...data, _id: data?._id || ObjectID() }
    : data;
};

export const useFetch = (parameters = {}) => {
  const { request = {}, options = {}, dependency = "", onDataChange } = parameters;
  const hasDependency = Object.hasOwn(parameters, "dependency");
  const hasRequest = Object.hasOwn(parameters, "request");
  const requestID = Array.isArray(request)
    ? request.map((r) => r.id ?? `${r.entity}.${r.method}`).join(",")
    : request.id ?? `${request.entity}.${request.method}`;

  const queryClient = useQueryClient();

  const { findEntity } = useEntity();

  //Handle Refresh
  const getAllQueries = useCallback(() => {
    const allQueries = queryClient.getQueriesData();
    return allQueries;
  }, [queryClient]);

  //Payload Utility
  const buildPayloads = useCallback((req, defaultMethod = "get") => {
    const payloads = Array.isArray(req) ? req : [req];
    return payloads.map(({ data = {}, ...payload }) => ({
      ...payload,
      entity: payload.entity ? findEntity([payload.entity]) : "",
      method: payload.method ?? defaultMethod,
      data: addIDToData(data),
    }));
  }, []);

  // Mutation setup
  const mutation = useMutation((req) => {
    const reqs = Array.isArray(req) ? req : [req];
    const headers = reqs.reduce((acc, { headers = {} }) => ({ ...acc, ...headers }), {});
    return fetchData({
      payloads: buildPayloads(reqs, "post"),
      node: reqs[0].node,
      headers: headers,
    });
  }, options);

  const memoizedQueryKey = useMemo(() => (dependency ? [requestID, dependency] : requestID), [requestID, dependency]);

  // Query setup
  const query = useQuery({
    queryKey: memoizedQueryKey,
    queryFn: hasRequest
      ? () => fetchData({ payloads: buildPayloads(request, "get"), node: request.node, headers: request.headers })
      : () => {},
    enabled: options.enabled !== undefined ? options.enabled : hasDependency ? !!dependency : hasRequest,
    retry: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    ...options,
  });

  useEffect(() => {
    if (query.data && onDataChange) {
      onDataChange(query.data);
    }
  }, [query.data, onDataChange]);

  const findQueriesByKey = useCallback(
    (queryKey) => {
      const allQueries = getAllQueries();

      const queries = [];

      allQueries.forEach((query) => {
        const firstElement = query[0];
        let queryID;
        typeof firstElement === "string" ? (queryID = firstElement) : (queryID = firstElement[0]);

        if (queryID === queryKey) {
          queries.push(query);
        }
      });
      return queries;
    },
    [getAllQueries]
  );

  const refresh = useCallback(
    (queryKeys = []) => {
      const queriesArr = Array.isArray(queryKeys) ? queryKeys : [queryKeys];
      queriesArr.forEach((queryKey) => {
        const queries = findQueriesByKey(queryKey);
        queries.forEach((query) => {
          queryClient.invalidateQueries({ queryKey: query[0] || query });
        });
      });
    },
    [queryClient, findQueriesByKey]
  );

  const updateCache = useCallback(
    (query = "", callback = () => {}) => {
      const queries = findQueriesByKey(query);

      queries.forEach((query) => {
        queryClient.setQueryData(query[0], callback);
      });
    },
    [queryClient, findQueriesByKey]
  );

  return useMemo(
    () => ({
      data: query.data || [],
      isLoading: mutation.isLoading || query.isLoading,
      isFetching: mutation.isFetching || query.isFetching,
      error: mutation.error || query.error,
      isError: mutation.isError || query.isError,
      post: async (req = {}) => {
        const { options = {} } = req;
        try {
          options.onMutate?.(req);
          const response = await mutation.mutateAsync(req);
          options.onSuccess?.(response?.data);
          options.select?.(response?.data);

          return response;
        } catch (error) {
          options.onError?.(error);
          return error;
        }
      },
      refresh,
      refetch: query.refetch,
      updateCache,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mutation.isLoading,
      mutation.isFetching,
      mutation.error,
      mutation.isError,
      mutation.mutate,
      mutation.mutateAsync,
      query.data,
      query.isLoading,
      query.isFetching,
      query.error,
      query.isError,
      query.refetch,
      refresh,
      updateCache,
    ]
  );
};
