import styles from "./BottomLink.module.css";
import { useStyling } from "../../hooks/useStyling.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Each } from "../layout/Each";

export const BottomLink = ({ links = [], floating = true }) => {
  const styling = useStyling(styles);
  return (
    <div
      className={styling("container")}
      style={{
        position: floating ? "absolute" : "static",
        bottom: floating ? "90px" : "auto",
      }}
    >
      <Each of={links} render={({ key, ...link }) => <Link key={key} {...link} />} />
    </div>
  );
};

const Link = ({ name, path }) => {
  const styling = useStyling(styles);
  const navigate = useNavigate();
  return (
    <div className={styling("link")} onClick={() => navigate(path)}>
      <p>{name}</p>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};
