import { createContext, useContext } from "react";

export const FormContext = createContext({
  register: () => {},
  getValues: () => {},
  clearErrors: () => {},
  submit: () => {},
  setFormValue: () => {},
  errors: {},
});

export const useForm = () => useContext(FormContext);

export const FormContextProvider = ({ children, ...props }) => {
  return <FormContext.Provider value={props}>{children}</FormContext.Provider>;
};
