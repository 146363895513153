import { useState, useCallback, useMemo } from "react";

export const useDocument = () => {
  const [doc, setDoc] = useState(null);

  const setThemeColour = useCallback((colour) => {
    setDoc((prev) => ({ ...prev, theme: colour }));
    document.querySelector("meta[name='theme-color']").setAttribute("content", colour);
  }, []);

  const setTitle = useCallback((title) => {
    setDoc((prev) => ({ ...prev, title }));
    document.querySelector("meta[name='title']").setAttribute("content", title);
  }, []);

  return useMemo(() => ({ doc, setDoc, setThemeColour, setTitle }), [doc, setDoc, setThemeColour, setTitle]);
};


