import { useRef, useEffect } from "react";

export const useInhibitScroll = () => {
  const scrollDivRef = useRef(null);

  useEffect(() => {
    const topDiv = scrollDivRef.current;
    let lastTouchY = 0; // Store the last Y position of a touch

    const handleTouchStart = (e) => {
      if (e.touches.length === 1) {
        // Only deal with one finger
        lastTouchY = e.touches[0].clientY; // Set the last Y
      }
    };

    const handleTouchMove = (e) => {
      const touchY = e.touches[0].clientY;
      const touchYDelta = touchY - lastTouchY;
      lastTouchY = touchY; // Update the last Y for the next move

      const isScrollingUp = touchYDelta > 0;
      const isAtTop = topDiv?.scrollTop === 0;
      const isScrollingDown = touchYDelta < 0;
      const isAtBottom = topDiv?.scrollHeight - topDiv.clientHeight === topDiv?.scrollTop;

      if ((isAtTop && isScrollingUp) || (isAtBottom && isScrollingDown)) {
        e.preventDefault(); // Prevents scrolling the parent
      }
      // For iOS, you might need to additionally use `e.stopPropagation()` to fully stop some unwanted behaviors.
    };

    topDiv.addEventListener("touchstart", handleTouchStart, { passive: false });
    topDiv.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      topDiv.removeEventListener("touchstart", handleTouchStart);
      topDiv.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  return scrollDivRef;
};
