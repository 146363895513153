import { useAuth, useDisplay, useUI } from "src/shiftly-ui";
import { useEffect } from "react";
import styles from "./Main.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import ShifterNavigation from "../UI/ShifterNavigation";

const Main = ({ children }) => {
  const {
    auth: { isAuthenticated },
  } = useAuth();

  const { loading } = useUI();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { isStandalone } = useDisplay();

  useEffect(() => {
    // Redirect to App Store if user is on browser version of the app
    if (process.env.REACT_APP_SHIFTLY_ENV === "LIVE" && !isStandalone)
      window.location.href = process.env.REACT_APP_SHIFTER_APPSTORE_URL;
  }, [isStandalone]);

  useEffect(() => {
    if (loading || pathname === "/portal") return;

    if (!isAuthenticated) {
      navigate("/portal?page=welcome");
    }
  }, [isAuthenticated, navigate, loading, pathname]);

  return (
    <div className={styles["container"]}>
      <ShifterNavigation />
      <div className={styles["content-wrapper"]}>{children}</div>
    </div>
  );
};

export default Main;
