import styles from "./AlertModal.module.css";
import { useStyling } from "../../hooks/useStyling";
import { Modal } from "./Modal";
import { Button } from "../buttons/Button";
import { Link } from "../buttons/Link";
import clsx from "clsx";

export const AlertModal = ({
  children,
  className,
  onClick = () => {},
  onSkip = () => {},
  buttonText,
  skipButtonText,
  ...props
}) => {
  const styling = useStyling(styles);
  const show = props.setShowModal || (() => {});

  const handleClick = () => {
    onClick();
    show(false);
  };

  const handleSkip = () => {
    onSkip();
    show(false);
  };

  return (
    <Modal {...props} className={clsx(styling("modal"), className)} overlayClassName={styling("overlay")}>
      <div className={styling("container")}>
        <div className={styling("children")}>{children}</div>

        <div className={styling("buttons")}>
          <Button onClick={handleClick} theme={"secondary"} className={styling("primary-btn")}>
            {buttonText}
          </Button>
          {skipButtonText && (
            <Link onClick={handleSkip} className={styling("skip-link")}>
              {skipButtonText}
            </Link>
          )}
        </div>
      </div>
    </Modal>
  );
};
