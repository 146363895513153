import { useInlineStyles } from "../../hooks/useInlineStyles";
import { useNavigate } from "react-router-dom";
import styles from "./Button.module.css";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";

export const Button = ({
  className,
  children,
  theme,
  size,
  onClick,
  icon,
  link,
  type = "button",
  loading = false,
  error,
  iconSide = "left",
  disabled,
  ...props
}) => {
  const style = useInlineStyles({ ...props });
  const navigate = useNavigate();

  const staticContent = useMemo(
    () => (
      <>
        {icon && (
          <FontAwesomeIcon icon={icon} className={clsx(styles["icon"], iconSide === "left" && styles["icon-left"])} />
        )}
        {children}
      </>
    ),
    [icon, iconSide, children]
  );

  const [renderedContent, setRenderedContent] = useState(staticContent);

  const initialRender = useRef(true);

  useLayoutEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (loading) {
      setRenderedContent(<FontAwesomeIcon icon={faSpinner} spin />);
      return;
    }

    const timeoutid = setTimeout(() => {
      if (!error) {
        setRenderedContent(staticContent);
        return;
      }

      if (error) {
        setRenderedContent(<FontAwesomeIcon icon={faTimes} />);
      } else {
        setRenderedContent(<FontAwesomeIcon icon={faCheck} />);
      }

      setTimeout(() => {
        setRenderedContent(staticContent);
      }, 2000);
    }, 300); // Small delay before showing the error or success icon to allow error state to propagate

    return () => clearTimeout(timeoutid);
  }, [loading, error, children, icon, iconSide, staticContent]);

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <button
      type={type}
      onClick={link ? () => navigate(link) : onClick}
      style={style}
      className={clsx(
        styles["button"],
        styles[theme],
        styles[size],
        loading && styles["loading"],
        className,
        disabled && styles["disabled"]
      )}
      disabled={loading || disabled}
      {...props}
    >
      <motion.div
        key={renderedContent}
        initial="visible"
        animate="visible"
        exit="hidden"
        variants={fadeVariants}
        transition={{ duration: 0.3 }}
        className={clsx(styles["content"], icon && styles[iconSide], loading && styles["loading"])}
      >
        {renderedContent}
      </motion.div>
    </button>
  );
};
