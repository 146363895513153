const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) ? true : "Please enter a valid email address.";
};

const validatePassword = (password = "") => {
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  if (password.length < minLength) {
    return `Password must be at least ${minLength} characters long.`;
  }

  if (!hasUppercase) {
    return "Password must contain at least one uppercase letter.";
  }

  if (!hasLowercase) {
    return "Password must contain at least one lowercase letter.";
  }

  if (!hasNumber) {
    return "Password must contain at least one number.";
  }

  if (!hasSpecialChar) {
    return "Password must contain at least one special character.";
  }

  return true;
};

function isNotBlank(str = "") {
  return str?.trim().length !== 0 ? true : "This field cannot be blank.";
}

const validateBSB = (bsb) => {
  return /^(?:\d{3}-?\d{3})$/.test(bsb) ? true : "Please enter a valid BSB (XXX-XXX).";
};

const validateBankAccountNumber = (accountNumber) => {
  return /^\d{5,9}$/.test(accountNumber) ? true : "Please enter a valid bank account number (5 to 9 digits).";
};

const validateDOB = (dob = "") => {
  const dt = new Date(dob);
  const date = new Date();
  date.setFullYear(date.getFullYear() - 100);
  if (dt < date) return "This doesn't seem right... please check your date of birth.";
  if (dt === "Invalid Date") return "Please enter a valid date.";
  const today = new Date();
  if (dt > today) return "Date of birth cannot be in the future.";
  const age = today.getFullYear() - dt.getFullYear();
  if (age < 16) return "You must be at least 16 years old.";
  return true;
};

const validateMobile = (number = "") => {
  if (!number) return true;
  number = number.replace(/\s/g, "");
  const regex = /^\d{10}$/;
  return regex.test(number) ? true : "Please enter a valid mobile number (10 digits).";
};

const validatePostcode = (postcode = "") => {
  return /^\d{4}$/.test(postcode) ? true : "Please enter a valid 4-digit postcode.";
};

const validateABN = (abn = "") => {
  if (!abn) return true;

  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

  if (!abn || abn.length !== 11) {
    return "ABN must be 11 digits.";
  }

  const digits = abn.split("").map(Number);
  digits[0] -= 1;

  const sum = digits.reduce((total, digit, index) => {
    return total + digit * weights[index];
  }, 0);

  return sum % 89 === 0 ? true : "Please enter a valid ABN.";
};

// On Google Maps API
const fullAddressValidator = (value) => {
  if (!value) return "Please enter a valid address.";
  let valid = true;

  for (let i = 0; i < 4; i++) {
    if (!value.terms?.[i]?.value) {
      valid = false;
      break;
    }
  }
  return valid ? true : "Please ensure the full address is selected.";
};

const validateAddress = (address = {}) => {
  return address?.street && address?.city && address?.state && address?.country && address?.pinpoint
    ? true
    : "Please enter a complete address (street, city, state, country, pinpoint).";
};

const isFutureDate = (date = "") => {
  try {
    if (date instanceof Date) {
      return date >= new Date() ? true : "The date cannot be in the past.";
    } else {
      const parsedDate = new Date(date);
      if (isNaN(parsedDate.getTime())) {
        throw new Error("Invalid input: Date is not a valid date.");
      }
      return parsedDate >= new Date() ? true : "The date cannot be in the past.";
    }
  } catch (error) {
    return error.message;
  }
};

const isValidDate = (date) => {
  return !isNaN(new Date(date)) ? true : "Please enter a valid date.";
};

const validateTFN = (tfn = "") => {
  if (tfn === null || tfn === "") return true;
  const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];

  tfn = tfn.replace(/\D/g, "");

  if (tfn.length === 9) {
    let sum = 0;
    for (let position = 0; position < weights.length; position++) {
      const digit = parseInt(tfn[position]);
      sum += weights[position] * digit;
    }
    return sum % 11 === 0 ? true : "Please enter a valid TFN.";
  }

  return "Please enter a valid TFN (9 digits).";
};

function isValidCreditCardNumber(cardNumber = "") {
  cardNumber = cardNumber.replace(/\D/g, "");

  if (!/^\d{16}$/.test(cardNumber)) {
    return "Please enter a valid 16-digit credit card number.";
  }

  const digits = cardNumber.split("").reverse().map(Number);
  let sum = 0;
  for (let i = 0; i < digits.length; i++) {
    let digit = digits[i];
    if (i % 2 === 1) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
  }

  return sum % 10 === 0 ? true : "Invalid credit card number.";
}

module.exports = {
  validateEmail,
  validatePassword,
  isNotBlank,
  validateBSB,
  validateBankAccountNumber,
  validateDOB,
  validateMobile,
  validateABN,
  fullAddressValidator,
  validatePostcode,
  validateAddress,
  isFutureDate,
  isValidDate,
  validateTFN,
  isValidCreditCardNumber,
};
