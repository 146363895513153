import { useEffect } from "react";
export const useSearchParams = (keys = [], callBack = () => {}, dependencies) => {

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    keys.forEach((key) => {
      params[key] = searchParams.get(key);
    });
    callBack(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(Array.isArray(dependencies) ? dependencies : [])]);
};
