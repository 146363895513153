import { createSlice } from "@reduxjs/toolkit";

const awardSlice = createSlice({
  name: "awards",
  initialState: {
    awards: [],
  },
  reducers: {
    setAwards(state, action) {
      state.awards = action.payload;
    },
  },
});

export const awardActions = awardSlice.actions;

export const awardSliceReducer = awardSlice.reducer;
