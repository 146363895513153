import { SideLayout, useStyling, Button, Input, useFetch, useAuth, FormV2 } from "src/shiftly-ui";

import styles from "../EmploymentDetails.module.css";

import { useCallback } from "react";

import { faSave } from "@fortawesome/pro-regular-svg-icons";

import useOnboardingComplete from "src/hooks/useOnboardingComplete";

const EDBankDetails = (props) => {
  const styling = useStyling(styles);

  const { user } = useAuth();

  const { post, isLoading, isError, updateCache } = useFetch({
    options: {
      onSuccess: (data) => updateCache("BankAccount.GetBankAccount", data),
    },
  });

  const { bankAccount } = useOnboardingComplete();

  const handleSubmit = useCallback(
    async (data) => {
      return post({
        entity: "BankAccount",
        method: "update",
        criteria: { user: user._id },
        data: {
          ...data,
          user: user?._id,
        },
        options: { upsert: true },
      });
    },
    [post, user]
  );

  return (
    <SideLayout heading={"Bank Details"} {...props}>
      <div className={styling("form-container")}>
        <FormV2 onSubmit={handleSubmit} initial={bankAccount}>
          <Input name={"account_name"} />
          <Input name={"bsb"} />
          <Input name={"account_number"} />
          <Button
            theme={"secondary"}
            type="submit"
            className={styling("save-btn")}
            icon={faSave}
            loading={isLoading}
            error={isError}
          >
            Save Bank Details
          </Button>
          <Button
            theme={"secondary-transparent"}
            className={styling("save-btn")}
            onClick={() => {
              props.setShow(false);
            }}
          >
            Cancel
          </Button>
        </FormV2>
      </div>
    </SideLayout>
  );
};

export default EDBankDetails;
