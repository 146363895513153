import { useAuth, calculateAge, Each, Button, truncate, useProfile, useStyling } from "src/shiftly-ui";
import styles from "./ShiftDetails.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faStar } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import noImage from "shiftly-ui/assets/png/no_img_placeholder.jpeg";
import useOnboardingComplete from "src/hooks/useOnboardingComplete";

const ShiftDetailsYourProfile = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { profile } = useProfile();

  const {
    todo: { total: onboardingComplete },
  } = useOnboardingComplete();

  const styling = useStyling(styles);

  return (
    <div className={styling("profile-container")}>
      <div className={styling("profile-heading")}>
        <h4>What will your employer see?</h4>
      </div>
      <div className={styling("profile-card")}>
        <div className={styling("profile-card-image")}>
          <img src={profile?.profile_picture || noImage} alt="profile" />
        </div>
        <div className={styling("profile-card-heading")}>
          <h4>
            {user?.first_name} {user?.last_name}, {calculateAge(user?.date_of_birth)}
          </h4>
        </div>
        {onboardingComplete ? (
          <>
            <div className={styling("profile-card-rating")}>
              <FontAwesomeIcon icon={faStar} />
              <p>{profile?.rating?.toFixed(2)}</p>
            </div>
            <div className={styling("profile-card-bio")}>
              <p>{truncate(profile?.bio, 110)}</p>
            </div>
            <CertContainer title="Work Experience" array={profile?.work_experience} />
            <CertContainer title="Qualifications" array={profile?.qualifications} />
          </>
        ) : (
          <>
            <p className={styling("profile-danger-text")}>Your profile is incomplete.</p>
            <p className={styling("profile-incomplete-sub-text")}>Complete your profile to apply to shifts</p>
          </>
        )}
        <Button
          icon={faArrowRight}
          iconSide="right"
          onClick={() => navigate("/profile")}
          className={styling("profile-card-button")}
          theme={"secondary"}
        >
          {onboardingComplete ? "Edit Profile" : "Complete Profile"}
        </Button>
      </div>
    </div>
  );
};

const CertContainer = ({ title, array = [] }) => {
  const styling = useStyling(styles);
  return array.length ? (
    <div className={styling("profile-card-cert")}>
      <h5>{title}</h5>
      <Each
        of={array}
        render={(item, index) => (
          <div key={index} className={styling("profile-cert")}>
            <p className={styling("profile-cert-title")}>{truncate(item.position ?? item.name, 20)}</p>
            <p className={styling("profile-cert-description")}>{truncate(item.description, 80)}</p>
          </div>
        )}
      />
    </div>
  ) : null;
};

export default ShiftDetailsYourProfile;
