import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDisplay } from "./useDisplay";
import { useFetch } from "./useFetch";
import { uiActions } from "../redux/uiSlice";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Otc", "Nov", "Dec"];

export const useUI = () => {
  const UI = useSelector((state) => state.ui);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { isMobile } = useDisplay();

  const { post: postNewSetting } = useFetch({});

  const getWindowDimensions = useCallback(() => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }, []);

  const excludedPaths = useMemo(() => {
    const arr = ["/portal"];
    if (isMobile && UI?.settings?.mode === "owner") {
      arr.push("/");
    }
    return arr;
  }, [isMobile, UI]);

  const updateSetting = useCallback(
    ({ setting, value }) => {
      postNewSetting({
        entity: "Setting",
        method: "update",
        criteria: { user: user?._id },
        data: { [setting]: value },
      });
      dispatch(uiActions.updateSetting({ setting, value }));
    },
    [dispatch, postNewSetting, user]
  );

  return useMemo(
    () => ({
      ...UI,
      newShiftModal: UI?.shifts?.newShiftModal,
      newShiftModalDefaults: UI?.shifts?.newShiftModalDefaults,
      mode: UI?.settings?.mode,
      businessMode: UI?.settings?.mode === "owner",
      currentPeriod: UI?.shifts?.currentPeriod,
      setCurrentPeriod: (period) => dispatch(uiActions.setCurrentPeriod(period)),
      updateSetting,
      startLoading: (params) => dispatch(uiActions.startLoading(params)),
      stopLoading: (params) => dispatch(uiActions.stopLoading(params)),
      getWindowDimensions,
      months,
      excludedPaths,
      showNewShiftModal: (mode) => dispatch(uiActions.showNewShiftModal(mode)),
      hideNewShiftModal: () => dispatch(uiActions.hideNewShiftModal()),
      setNewShiftModalDefaults: (defaults) => dispatch(uiActions.setNewShiftModalDefaults(defaults)),
      setViewMode: (mode) => dispatch(uiActions.setViewMode(mode)),
      setSettings: (settings) => dispatch(uiActions.setSettings(settings)),
    }),
    [UI, dispatch, updateSetting, getWindowDimensions, excludedPaths]
  );
};
