import styles from "./Fallback.module.css";
import { useStyling } from "src/shiftly-ui";

const Fallback = () => {
  const styling = useStyling(styles);

  return <g1>Oh no!</g1>;
};

export default Fallback;
