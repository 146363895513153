import { useUI, useAuth, useFetch } from "src/shiftly-ui";
import { useRef, useCallback, useState, useEffect } from "react";
import useShifts from "src/hooks/useShifts";
import { useSprings } from "@react-spring/web";
import { useDrag } from "react-use-gesture";

const animateEmoticon = (setter) => {
  setter(true);

  setTimeout(() => {
    setter(false);
  }, 1500);
};

const useCardSwipe = () => {
  const animating = useRef(false);

  const {
    filteredShifts: shifts = [],
    page,
    setPage,
    shiftStack,
    setPointer,
    pushToStack,
    popFromStack,
    clearStack,
    handleSearch,
  } = useShifts();

  const [loveAnimation, setLoveAnimation] = useState(false);
  const [trashAnimation, setTrashAnimation] = useState(false);

  const { user } = useAuth();
  const { getWindowDimensions } = useUI();
  const { pointer: positionPointer, leftStack, rightStack, scrollStack } = shiftStack;
  const { height: screenHeight } = getWindowDimensions();

  const { post: postShiftAction, updateCache } = useFetch({
    options: {
      onMutate: ({ data, payload }) => {
        const { action } = data;

        action === "saved"
          ? updateCache("Shift.GetSavedShiftsFromShiftIDs", (oldData = []) => {
              return [...oldData, payload];
            })
          : updateCache("ActionedShift.GetTrashedShifts", (oldData = []) => {
              return [...oldData, { shift: payload }];
            });
      },
    },
  });

  const actionShift = useCallback(
    (shift, action) => {
      postShiftAction({
        entity: "ActionedShift",
        method: "update",
        criteria: {
          user: user?._id,
          shift: shift._id,
        },
        data: {
          action,
          user: user?._id,
          shift: shift._id,
        },
        options: {
          upsert: true,
        },
        payload: shift,
      });
    },
    [user, postShiftAction]
  );

  const to = useCallback(
    (i, resetting = false) => {
      let minus = resetting ? 0 : scrollStack.length + leftStack.length + rightStack.length;

      if (scrollStack.includes(i) && !resetting) {
        return {
          y: -(0.9 * screenHeight - 240),
          x: 0,
          config: {
            duration: 0,
          },
        };
      }

      if (leftStack.includes(i) && !resetting) {
        return {
          x: -500,
          config: {
            duration: 0,
          },
        };
      }

      if (rightStack.includes(i) && !resetting) {
        return {
          x: 500,
          config: {
            duration: 0,
          },
        };
      }

      if (i - minus < 3 && i - minus >= 0) {
        return {
          x: 0,
          y: (i - minus) * 20,
          scale: 1 - (i - minus) * 0.05,
          delay: 0,
          boxShadow: "0px 4px 15px 1px rgba(173, 173, 173, 0.2)",
          zIndex: 3 - (i - minus),
          opacity: 1,
          config: {
            duration: 400,
          },
        };
      }

      return {
        x: 0,
        y: 70,
        scale: 0,
        boxShadow: "0px 4px 15px 1px rgba(173, 173, 173, 0)",
        zIndex: 0,
        opacity: 0,
        config: {
          duration: 200,
        },
      };
    },
    [screenHeight, scrollStack, leftStack, rightStack]
  );

  const from = (_i) => {
    if (_i < 3 && _i >= 0) {
      return { x: 0, scale: 1, y: 0, zIndex: 3 - _i };
    }

    return { x: 0, scale: 1, y: 0, zIndex: 3 - _i };
  };

  const [springs, api] = useSprings(shifts.length, (i) => ({
    ...to(i),
    from: from(i),
  }));

  const resetApi = useCallback(() => {
    api.start((i) => {
      return {
        ...to(i, true),
        from: {
          ...to(i, true),
        },
      };
    });
  }, [api, to]);

  useEffect(() => {
    resetApi();
    //eslint-disable-next-line
  }, [shifts]);

  const stopAnimating = useCallback(() => {
    setTimeout(() => {
      animating.current = false;
    }, 300);
  }, []);

  const handleLastCardSwipe = useCallback(() => {
    stopAnimating();

    setPointer(0);

    clearStack({ stack: "leftStack" });
    clearStack({ stack: "rightStack" });
    clearStack({ stack: "scrollStack" });

    const currentSearch = JSON.parse(localStorage.getItem("shiftFilter") ?? "{}");
    setPage(page + 1);
    if (Object.keys(currentSearch).length > 0) {
      handleSearch({
        what: currentSearch.what,
        where: currentSearch.where,
        when: currentSearch.when,
        wage: currentSearch.wage,
        user,
        page: page + 1,
      });
    }
  }, [stopAnimating, page, setPage, setPointer, clearStack, handleSearch, user]);

  const swipeUp = useCallback(
    (i) => {
      pushToStack({ stack: "scrollStack", value: i });

      stopAnimating();

      return {
        y: -(0.9 * screenHeight - 250),
        x: 0,
        boxShadow: "0px 4px 15px 1px rgba(173, 173, 173, 0)",
        config: {
          duration: 200,
        },
      };
    },
    [pushToStack, screenHeight, stopAnimating]
  );

  const swipeLeft = useCallback(
    (i) => {
      const shift = shifts[i];

      actionShift(shift, "trashed");
      animateEmoticon(setTrashAnimation);
      pushToStack({ stack: "leftStack", value: i });
      stopAnimating();

      return {
        x: -500,
        config: {
          duration: 200,
        },
      };
    },
    [stopAnimating, pushToStack, setTrashAnimation, shifts, actionShift]
  );

  const swipeRight = useCallback(
    (i) => {
      const shift = shifts[i];

      actionShift(shift, "saved");
      animateEmoticon(setLoveAnimation);
      pushToStack({ stack: "rightStack", value: i });

      stopAnimating();

      return {
        x: 500,
        config: {
          duration: 200,
        },
      };
    },
    [stopAnimating, pushToStack, setLoveAnimation, actionShift, shifts]
  );

  const animateCard = useCallback(
    ({ x, y, z, shadow = 0.2, delay = 0, config = {}, scale = 1 }) => {
      stopAnimating();

      return {
        x,
        y,
        scale,
        delay,
        zIndex: z,
        boxShadow: `0px 4px 15px 1px rgba(173, 173, 173, ${shadow})`,
        config: {
          duration: 200,
          ...config,
        },
      };
    },
    [stopAnimating]
  );

  const bind = useDrag(({ args: [index], down: dragging, movement: [mx, my], velocity }) => {
    if (index !== positionPointer || animating.current) return;

    const trigger = velocity > 0.2;
    let direction;
    let swiped = false;
    const scale = dragging ? 1.03 : 1;
    let swipedDown = false;

    const config = {
      friction: 50,
      tension: dragging ? 800 : swiped ? 200 : 500,
    };

    if (Math.abs(mx) > Math.abs(my)) {
      direction = mx > 0 ? "right" : "left";
    } else {
      direction = my > 0 ? "down" : "up";
    }

    api.start((i) => {
      const currentCard = index;
      let nextCard = index + 1;
      let nextNextCard = index + 2;
      const previousCard = scrollStack[scrollStack.length - 1];
      let hiddenCard = index + 3;
      let nextFound = false;
      let nextNextFound = false;
      let hiddenFound = false;

      while (!nextFound) {
        if (leftStack.includes(nextCard)) {
          nextCard += 1;
        } else if (rightStack.includes(nextCard)) {
          nextCard += 1;
        } else {
          nextFound = true;
        }
      }

      while (!nextNextFound) {
        if (leftStack.includes(nextNextCard)) {
          nextNextCard += 1;
        }

        if (rightStack.includes(nextNextCard)) {
          nextNextCard += 1;
        } else {
          nextNextFound = true;
        }
      }

      while (!hiddenFound) {
        if (leftStack.includes(hiddenCard)) {
          hiddenCard += 1;
        }

        if (rightStack.includes(hiddenCard)) {
          hiddenCard += 1;
        } else {
          hiddenFound = true;
        }
      }

      if (swiped && direction === "down" && i === shifts.length - 1) {
        popFromStack({ stack: "scrollStack" });
      }

      if (i > index + 3) return; // We're only interested in changing spring-data for unswiped shifts i > 2)

      if (!dragging && trigger) {
        animating.current = true;

        swiped = true;
      }

      // If we're swiping left, right or up, animate the current card
      if (i === currentCard && swiped) {
        animating.current = true;

        if (direction === "left") {
          setPointer(nextCard);

          return swipeLeft(currentCard);
        }

        if (direction === "right") {
          setPointer(nextCard);

          return swipeRight(currentCard);
        }

        if (direction === "up") {
          setPointer(nextCard);

          return swipeUp(currentCard);
        }

        if (direction === "down") {
          swipedDown = true;
        }
      }

      if ((swiped && direction === "down" && i < positionPointer) || swipedDown) {
        if (previousCard < 0) return stopAnimating();

        if (i === previousCard && swiped) {
          setPointer(previousCard);

          return animateCard({
            x: 0,
            y: 0,
            z: 3,
            config: { duration: 200 },
            scale: 1,
          });
        }

        if (i === currentCard && swiped) {
          if (scrollStack.length === 0) {
            return animateCard({ x: 0, y: 0, z: 3, scale: 1 });
          }

          return animateCard({
            x: 0,
            y: 35,
            z: 2,
            config: { duration: 200 },
            scale: 0.9,
          });
        }

        if (i === nextCard && swiped) {
          if (scrollStack.length === 0) {
            return animateCard({ x: 0, y: 35, z: 2, scale: 0.9 });
          }

          return animateCard({ x: 0, y: 70, z: 1, scale: 0.8 });
        }

        if (i === nextNextCard && swiped) {
          if (scrollStack.length === 0) {
            return animateCard({
              x: 0,
              y: 70,
              z: 1,
              shadow: 0.2,
              scale: 0.8,
            });
          }

          return animateCard({ x: 0, y: 70, z: 0, shadow: 0, scale: 0.8 });
        }

        return stopAnimating();
      }

      if (i === index && !swiped) {
        return {
          x: dragging ? mx : 0,
          y: dragging ? (my < 0 ? my : 0) : 0,
          scale,
          config,
        };
      }

      if (direction === "down") {
        return;
      }

      if (i === nextCard && swiped) {
        return animateCard({ x: 0, y: 0, z: 3, scale: 1 });
      }

      if (i === nextNextCard && swiped) {
        return animateCard({ x: 0, y: 35, z: 2, scale: 0.9 });
      }

      if (i === hiddenCard && swiped) {
        return animateCard({ x: 0, y: 70, z: 1, scale: 0.8 });
      }
    });

    if (index === shifts.length - 1 && !dragging && trigger) {
      animating.current = true;

      setTimeout(() => {
        handleLastCardSwipe(direction === "up" && shifts[index]);
      }, 500);
    }
  });

  return {
    springs,
    bind,
    shifts,
    loveAnimation,
    trashAnimation,
    actionShift,
    positionPointer,
  };
};

export default useCardSwipe;
